import React from 'react';
import {
  Person
} from "@mui/icons-material";

const NotificationCard = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto">
      <div className="flex flex-col h-full border-b border-gray-300">
        <div className="flex flex-col items-center border-b border-gray-300 mb-4">
          <div className="bg-blue-500 text-white p-3 rounded-full mb-4">
            <span className="text-xl">🔒</span>
          </div>
          <h3 className="text-lg font-semibold text-center mb-4">
            Brad's sports team has an upcoming game
          </h3>
        </div>
        <div className="flex items-center mb-4 mt-4 p-2 flex-grow">
          <Person className="ml-2 mr-2 text-gray-700" />
          <div className="flex-1 mr-2">
            <div className="bg-gray-200 h-4 rounded mb-2 w-1/4"></div>
            <div className="h-4"></div>
            <div className="bg-gray-200 h-4 rounded mb-2"></div>
            <div className="bg-gray-200 h-4 rounded mb-2"></div>
            <div className="h-4"></div>
            <div className="bg-gray-200 h-4 rounded mb-2 w-1/4"></div>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <div className="bg-blue-500 text-white py-2 px-4 rounded-lg">
            Send
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
