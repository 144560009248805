import {
  SportsSoccer,
  BusinessOutlined,
  SportsBasketball,
  ReportOutlined,
  Thunderstorm,
  Restaurant,
  Construction,
  Notifications,
  EmojiPeople
} from "@mui/icons-material";

export const getPulseTypeDetails = (value) => {
  const pulseTypeDetails = {
    refresh: {
      icon: <EmojiPeople className="text-blue-500"/>,
      title: "Find New Leads",
      type: "refresh",
      description: "Pulse will find new prospects which match your search criteria every day. Be sure to check in and give a thumbs up!",
      smallIcon: <Notifications className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "completed", // or "inProgress" / "notStarted",
      draftExample: {
        subject: "Pulse found you 10 more prospects!",
        body: `Check your community "VPs of Sales in the Technology industry who are located in California" to view the fresh leads. We will automatically scan any attached pulses for these new people that you have attached to your community`,
      },
    },
    weather: {
      icon: <Thunderstorm className="text-blue-500"/>,
      title: "Notable Weather",
      type: "weather",
      description: "Draft a personalized message when Pulse finds notable weather that may be impacting your leads & customers",
      smallIcon: <Notifications className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "completed", // or "inProgress" / "notStarted"
      draftExample: {
        subject: "Snow Storm Heading for New York - A Thoughtful Gesture",
        body: `Hi David,\n\nI hope this email finds you well. I came across a snow storm warning for New York, and I wanted to make sure you are safe and warm during these winter days.\n\n
        To help make things a bit easier, I would be happy to send you a gift card for a local delivery service or a streaming platform, whichever you prefer. Please let me know your preferred option, and I'll arrange it for you.\n\n
        Take care and stay warm.\n\n
        Best regards,\n
        Raffi`,
      },
    },
    sports: {
      icon: <SportsBasketball className="text-orange-600" />,
      title: "Sports Game",
      type: "sports",
      description: "When a sports event that your lead may be interested in is upcoming, generate a personalized message",
      smallIcon: <Notifications className="text-blue-500 w-4 h-4 absolute top-0 right-0" />,
      status: "completed",
      draftExample: {
        subject: "Hey Nicholas - Watching the Dodgers game later this week?",
        body: `I noticed you're in Los Angeles and thought you're probably catching the Dodgers game.\n\n
        Looks like the Dodgers are set to win. Considering their strong lineup and recent performance, I'm expecting the Dodgers to take home the points!\n\n
        What do you think?\n\n
        Thanks Nicholas!\n\n
        Best,\n Raffi\n\n
        PS: If the Dodgers kick the Giants' butt, I'll give you a $15 gift card to a local cafe of your choosing for a 15-minute chat.`
      }
    },
    newRestaurantNearby: {
      icon: <Restaurant />,
      title: "New Restaurant Nearby",
      type: "newRestaurantNearby",
      description: "When a new well-reviewed restaurant opens up nearby, Pulse will recommend it to a person.",
      smallIcon: <Restaurant className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "notStarted",
    },
    startupFundingRaised: {
      icon: <BusinessOutlined />,
      title: "Startup Funding Raised",
      type: "startupFundingRaised",
      description: "When a company secures funding, Pulse will draft a congratulations outreach.",
      smallIcon: <BusinessOutlined className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "notStarted",
    },
    softwareReviewWritten: {
      icon: <ReportOutlined />,
      title: "Software Review Written",
      type: "softwareReviewWritten",
      description: "When a prospect writes a review about a software in your industry, Pulse will draft an outreach to help you intervene.",
      smallIcon: <ReportOutlined className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "notStarted",
    },
    industryNews: {
      icon: <Construction />,
      title: "Industry News",
      type: "industryNews",
      description: "When significant industry news occurs, Pulse will notify you or draft outreach to impacted prospects and customers.",
      smallIcon: <Construction className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "inProgress",
    },
    sportsTeamWins: {
      icon: <SportsSoccer />,
      title: "Sports Team Wins",
      type: "sportsTeamWins",
      description: "When a team wins that a prospect is interested in, Pulse will draft an outreach to spark up a conversation.",
      smallIcon: <SportsSoccer className="text-blue-500 w-4 h-4 mt-1 mr-1 absolute top-0 right-0" />,
      status: "notStarted",
    },
    industryEvent: {
      icon: <Construction />,
      title: "Events",
      type: "events",
      description: "When an event for a leads industry, in their location is occurring, generate a personalized message to initiate a relationship",
      smallIcon: <Notifications className="text-blue-500 w-4 h-4 absolute top-0 right-0" />,
      status: "inProgress",
    }
  };

  const specificDetails = pulseTypeDetails[value] || [];

  const inProgressDetails = Object.values(pulseTypeDetails).filter(detail =>
    detail.status === "inProgress" && detail.type !== value
  );

  return specificDetails ? [specificDetails, ...inProgressDetails] : inProgressDetails;
};