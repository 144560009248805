import React from 'react'
import { useDispatch } from 'react-redux';
import { actions } from 'users/ducks';
import { Google } from '@mui/icons-material';
import Loader from 'loader';

const stepFour = () => {
  const redirectUri = process.env.REACT_APP_SEND_URI_PROD;
  const dispatch = useDispatch();

  const handleConnectEmail = () => {
    // Dispatch the update onboarding action
    dispatch(actions.updateOnboarding());
    window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=306269071464-nl9fsl63a4el8qh1sdh9m3f3lrhk7d08.apps.googleusercontent.com&redirect_uri=${redirectUri}&scope=https://www.googleapis.com/auth/gmail.send+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&state=tbb3cU7uAdS0BXVmnWQKCZ1L4Xr0fl&access_type=offline`;
  };

  return (
    <div className='flex flex-col items-center space-y-4'>
      <div className='mb-20'>
        <Loader />
      </div>
      <h2 className='font-semibold text-2xl p-6'>Connect your email to start</h2>
      <button
        onClick={handleConnectEmail}
        className="flex items-center px-6 py-4 text-blue-500 border border-blue-500 rounded hover:bg-blue-600 hover:text-white mb-4"
      >
        <Google className="mr-2" />
        Connect Gmail
      </button>
      <p className='text-sm p-4'>
        This email will be used for outreach
      </p>
      <p className='text-sm'>
        Note: Please select the same email you signed up with
      </p>
    </div>
  )
}

export default stepFour
